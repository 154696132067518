import React from "react"
import { Link } from "gatsby"
import { useStyletron } from "baseui"
import { Button } from "baseui/button"
import { Block } from "baseui/block"
import { Cell } from "baseui/layout-grid"
import { Display, DisplayDescription } from "../../components/typography"
import Container from "../../components/UI/Container"
import FlushedGrid from "../../components/FlushedGrid"

const MotifLanding = () => {
  const [css] = useStyletron()

  return (
    <Block
      paddingTop={["120px", "140px", "180px"]}
      paddingBottom="30px"
      className={css({
        background: "rgb(13,87,163)",
        background:
          "linear-gradient(90deg, rgba(13,87,163,1) 0%, rgba(32,164,207,1) 100%)",
      })}
    >
      <Container>
        <FlushedGrid gridGutters={[0, 48]} gridGaps={28}>
          <Cell span={[12, 6]}>
            <Block
              display="flex"
              flexDirection="column"
              marginLeft={["18px", "10%", "20%", 0]}
            >
              <Display color="white">
                Motif Graph Intelligence Platform
              </Display>
              <DisplayDescription color="white">
              No-code visual interface that helps analysts transform connected data to actionable insights.
              </DisplayDescription>
              <ButtonGroup />
            </Block>
          </Cell> 
          <Cell span={[12, 6]} align="center">
            <Block display={["none", "block"]} marginBottom="40px">
              <img src="https://cylynx.imgix.net/uploads/motif-demo.gif"/>
            </Block>
          </Cell>
        </FlushedGrid>
      </Container>
    </Block>
  )
}

const ButtonGroup = () => (
  <Block display={["block", "flex"]} marginTop="20px">
    <a href="https://www.motif.gl/register" target="_blank">
      <Button
        id="motif-signup"
        overrides={{
          BaseButton: {
            style: {
              width: "220px",
            },
          },
        }}
      >
        Sign up now!
      </Button>
    </a>
    <a
      href="https://www.motif.gl/explorer/aa813d28-4a6c-4289-935f-77b16ef90ab2/editor"
      target="_blank"
    >
      <Button
        kind="secondary"
        overrides={{
          BaseButton: {
            style: {
              color: "black",
              width: "220px",
              maxWidth: "220px",
              backgroundColor: "white",
              ":hover": {
                backgroundColor: "white",
              },
            },
          },
        }}
      >
        Sample Workspace
      </Button>
    </a>
  </Block>
)

export default MotifLanding
