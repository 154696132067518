import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
// import PropTypes from "prop-types"
import Fade from "react-reveal/Fade"
// import Slide from "react-reveal/Slide"
// import { Breadcrumbs } from "baseui/breadcrumbs"
// import { StyledLink } from "baseui/link"
import { Block } from "baseui/block"
import { Cell } from "baseui/layout-grid"
import {
  Title,
  SubTitle,
  ItemTitle,
  // ItemDescription,
  Description,
} from "../../components/typography"
import Text from "../../components/Text"
import FlushedGrid from "../../components/FlushedGrid"
import Container from "../../components/UI/Container"

const MotifFeatures = () => {
  const Data = useStaticQuery(graphql`
    query {
      importoptions: imgixImage(url: "/uploads/motif-import-options.png") {
        fluid(maxWidth: 800) {
          ...GatsbyImgixFluid
        }
      }
      layout: imgixImage(url: "/uploads/motif-layout-options.png") {
        fluid(maxWidth: 800) {
          ...GatsbyImgixFluid
        }
      }
      styling: imgixImage(url: "/uploads/motif-styling-options.png") {
        fluid(maxWidth: 800) {
          ...GatsbyImgixFluid
        }
      }
      timeseries: imgixImage(url: "/uploads/motif-timeseries-options.png") {
        fluid(maxWidth: 800) {
          ...GatsbyImgixFluid
        }
      }
      collaborate: imgixImage(url: "/uploads/share-collaborate.png") {
        fluid(maxWidth: 800) {
          ...GatsbyImgixFluid
        }
      }
    }
  `)

  return (
    <Block paddingTop={["50px", "80px"]} paddingBottom={["50px", "80px"]}>
      <Container>
        <Block marginBottom={["40px", "40px", "40px", "60px"]}>
          <SubTitle textAlign="center">
            Accelerate Visual Discovery with Motif
          </SubTitle>
          <Title textAlign="center">Features and Capabilities</Title>
        </Block>
        <Block marginTop="24px">
          <FlushedGrid gridGutters={[0, 24]} gridGaps={24}>
            <Cell span={[12, 6]} align="center" order={[2, 1]}>
              <Fade up>
                <Block
                  display="flex"
                  flexDirection="column"
                  marginLeft={["18px", "10%", "20%", 0]}
                >
                  <ItemTitle>Easy Data Import</ItemTitle>

                  <Block marginLeft="14px">
                    <Description paddingBottom="8px">
                      Drag and drop csv
                    </Description>
                    <Description paddingBottom="8px">
                      Neo4J connector
                    </Description>
                    <Description paddingBottom="8px">
                      Jupyter integration (coming soon!)
                    </Description>
                    <Description paddingBottom="8px">
                      Easily extensible with custom API endpoints
                    </Description>
                  </Block>
                </Block>
              </Fade>
            </Cell>
            <Cell span={[12, 6]} align="center" order={[1, 2]}>
              <Fade up>
                <Block
                  display="flex"
                  alignItems="center"
                  height="100%"
                  maxWidth="600px"
                  marginLeft={["18px", "10%", "20%", 0]}
                >
                  <Img
                    fluid={{ ...Data.importoptions.fluid }}
                    style={{ width: "100%" }}
                    alt="Connections Image"
                  />
                </Block>
              </Fade>
            </Cell>
          </FlushedGrid>
        </Block>

        <Block marginTop="24px">
          <FlushedGrid gridGutters={[0, 24]} gridGaps={24}>
            <Cell span={[12, 6]} align="center" order={[2, 1]}>
              <Fade up>
                <Block
                  display="flex"
                  flexDirection="column"
                  marginLeft={["18px", "10%", "20%", 0]}
                >
                  <ItemTitle>Multiple Layout Options</ItemTitle>

                  <Block marginLeft="14px">
                    <Description paddingBottom="8px">
                      Force directed layout
                    </Description>
                    <Description paddingBottom="8px">
                      Sequential layout for ordered data
                    </Description>
                    <Description paddingBottom="8px">
                      Radial layout for relationships
                    </Description>
                  </Block>
                </Block>
              </Fade>
            </Cell>
            <Cell span={[12, 6]} align="center" order={[1, 2]}>
              <Fade up>
                <Block
                  display="flex"
                  alignItems="center"
                  height="100%"
                  maxWidth="600px"
                  marginLeft={["18px", "10%", "20%", 0]}
                >
                  <Img
                    fluid={{ ...Data.layout.fluid }}
                    style={{ width: "100%" }}
                    alt="Connections Image"
                  />
                </Block>
              </Fade>
            </Cell>
          </FlushedGrid>
        </Block>

        <Block marginTop="24px">
          <FlushedGrid gridGutters={[0, 24]} gridGaps={24}>
            <Cell span={[12, 6]} align="center" order={[2, 1]}>
              <Fade up>
                <Block
                  display="flex"
                  flexDirection="column"
                  marginLeft={["18px", "10%", "20%", 0]}
                >
                  <ItemTitle>Comprehensive Styling Options</ItemTitle>
                  <Block marginLeft="14px">
                    <Description paddingBottom="8px">
                      Map node property to legend
                    </Description>
                    <Description paddingBottom="8px">
                      Size nodes based on number of connections (degree)
                    </Description>
                    <Description paddingBottom="8px">
                      Configure node and edge labels
                    </Description>
                  </Block>
                </Block>
              </Fade>
            </Cell>
            <Cell span={[12, 6]} align="center" order={[1, 2]}>
              <Fade up>
                <Block
                  display="flex"
                  alignItems="center"
                  height="100%"
                  maxWidth="600px"
                  marginLeft={["18px", "10%", "20%", 0]}
                >
                  <Img
                    fluid={{ ...Data.styling.fluid }}
                    style={{ width: "100%" }}
                    alt="Connections Image"
                  />
                </Block>
              </Fade>
            </Cell>
          </FlushedGrid>
        </Block>

        <Block marginTop="24px">
          <FlushedGrid gridGutters={[0, 24]} gridGaps={24}>
            <Cell span={[12, 6]} align="center" order={[2, 1]}>
              <Fade up>
                <Block
                  display="flex"
                  flexDirection="column"
                  marginLeft={["18px", "10%", "20%", 0]}
                >
                  <ItemTitle>Powerful Filters</ItemTitle>
                  <Block marginLeft="14px">
                    <Description paddingBottom="8px">
                      First class time series support
                    </Description>
                    <Description paddingBottom="8px">
                      Soft or hard filters for investigations
                    </Description>
                    <Description paddingBottom="8px">
                      Play back time series activities
                    </Description>
                  </Block>
                </Block>
              </Fade>
            </Cell>
            <Cell span={[12, 6]} align="center" order={[1, 2]}>
              <Fade up>
                <Block
                  display="flex"
                  alignItems="center"
                  height="100%"
                  maxWidth="600px"
                  marginLeft={["18px", "10%", "20%", 0]}
                >
                  <Img
                    fluid={{ ...Data.timeseries.fluid }}
                    style={{ width: "100%" }}
                    alt="Connections Image"
                  />
                </Block>
              </Fade>
            </Cell>
          </FlushedGrid>
        </Block>

        <Block marginTop="24px">
          <FlushedGrid gridGutters={[0, 24]} gridGaps={24}>
            <Cell span={[12, 6]} align="center" order={[2, 1]}>
              <Fade up>
                <Block
                  display="flex"
                  flexDirection="column"
                  marginLeft={["18px", "10%", "20%", 0]}
                >
                  <ItemTitle>Share and Collaborate (Enterprise Edition)</ItemTitle>
                  <Block marginLeft="14px">
                    <Description paddingBottom="8px">
                      Collaborate across teams
                    </Description>
                    <Description paddingBottom="8px">
                      File access control 
                    </Description>
                    <Description paddingBottom="8px">
                     Case management and commenting capabilities
                    </Description>
                  </Block>
                </Block>
              </Fade>
            </Cell>
            <Cell span={[12, 6]} align="center" order={[1, 2]}>
              <Fade up>
                <Block
                  display="flex"
                  alignItems="center"
                  height="100%"
                  maxWidth="600px"
                  marginLeft={["18px", "10%", "20%", 0]}
                >
                  <Img
                    fluid={{ ...Data.collaborate.fluid }}
                    style={{ width: "70%" }}
                    alt="Connections Image"
                  />
                </Block>
              </Fade>
            </Cell>
          </FlushedGrid>
        </Block>
      </Container>
    </Block>
  )
}

export default MotifFeatures
