import React from "react"

import { Block } from "baseui/block"
import { SEOPage } from "../components/SEO"
import Layout from "../components/layout"
import MotifLanding from "../containers/MotifProductPage/MotifLanding"
import MotifFeatures from "../containers/MotifProductPage/MotifFeatures"
import WhyMotif from "../containers/MotifProductPage/WhyMotif"
import MotifUseCases from "../containers/MotifProductPage/MotifUseCases"
import MotifFAQs from "../containers/MotifProductPage/MotifFAQs"
import { Newsletter } from "../containers/Newsletter"

export default () => {
  return (
    <Layout>
      <SEOPage
        title="Motif Graph Intelligence Platform"
        description="The graph visualisation and BI tool you deserve. Unlock the value of your connected data and save on developers costs."
      />
      <MotifLanding />
      <WhyMotif />
      <MotifUseCases />
      <MotifFeatures />
      <MotifFAQs />
      <Block marginBottom="150px" />
      <Newsletter ButtonText="Download the Infographic Report" />
    </Layout>
  )
}
