import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Fade from "react-reveal"
import { Block } from "baseui/block"
import { Cell } from "baseui/layout-grid"
import { Title, SubTitle, Description } from "../../components/typography"
import Container from "../../components/UI/Container"
import FlushedGrid from "../../components/FlushedGrid"

const MotifUseCases = () => {
  const Data = useStaticQuery(graphql`
    query {
      imgixImage(url: "/uploads/social-media-connections.png") {
        fluid(maxWidth: 800) {
          ...GatsbyImgixFluid
        }
      }
    }
  `)

  return (
    <Block paddingTop={["50px", "80px"]} paddingBottom={["50px", "80px"]}>
      <Container>
        <FlushedGrid gridGutters={[0, 56]} gridGaps={24}>
          <Cell span={[12, 6]} order={[2, 1]} align="center">
            <Block
              display="flex"
              flexDirection="column"
              marginLeft={["18px", "10%", "20%", 0]}
            >
              <SubTitle>Value Proposition</SubTitle>
              <Title>Why customers choose us?</Title>
              <Fade up>
                <span>
                  <Description paddingBottom="20px">
                    1) Easily represent time-series or relational data
                  </Description>
                  <Description paddingBottom="20px">
                    2) Integration with their favourite graph database solution
                    and data science tools
                  </Description>
                  <Description paddingBottom="20px">
                    3) Cut down the development time of a network visualisation
                    solution by 6 months
                  </Description>
                </span>
              </Fade>
            </Block>
          </Cell>
          <Cell span={[12, 6]} order={[1, 2]} align="center">
            <Block
              display="flex"
              alignItems="center"
              height="100%"
              maxWidth="600px"
              marginLeft={["18px", "10%", "20%", 0]}
            >
              <Img
                fluid={{ ...Data.imgixImage.fluid }}
                style={{ width: "100%" }}
                alt="Connections Image"
              />
            </Block>
          </Cell>
        </FlushedGrid>
      </Container>
    </Block>
  )
}

export default MotifUseCases
