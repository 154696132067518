import React from "react"
import { Link } from "gatsby"
import { Accordion, Panel } from "baseui/accordion"
import { Block } from "baseui/block"
import { Title } from "../../components/typography"
import Container from "../../components/UI/Container"

const MotifFAQs = () => {
  return (
    <Block paddingTop={["50px", "80px"]} paddingBottom={["50px", "80px"]}>
      <Container>
        <Block marginBottom={["40px", "40px", "40px", "60px"]}>
          <Title textAlign="center">FAQs</Title>
        </Block>
        <Accordion renderAll>
          <Panel title="What is Motif?">
            Motif is a no-code graph intelligence software that translates
            connected data into business insights. It allows businesses to
            connect the dots between different data points and helps users speed
            up data exploration, analysis and collaboration.
          </Panel>
          <Panel title="How is Motif different from Neo4J, CosmosDB, ArangoDB or other graph databases?">
            Graph databases take care of storage, processing and analysis. Motif
            is a graph intelligence software to make graph data into business
            intelligence so that your team can make better data driven
            decisions. Motif is to graph databases as Tableau or Power BI is to
            relational databases.
          </Panel>
          <Panel title="How is Motif different from D3, Sigma.js, Keylines or other visualization toolkit?">
            Javascript visualisation libraries offer a framework for developers
            to build a wide variety of solutions on. It takes a lot of time and
            effort to build a comprehensive data visualisation tool that
            supports different work flows and data types. Motif is an out of the
            box application that does that. It is built on G6, D3 and other
            popular libraries to offer you an out of the box solution that
            speeds up time to market. Of course, it is also a Javascript / React
            and can be further customised for your use case.
          </Panel>
          <Panel title="How is Motif different from Networkx, Igraph, SNAP or other graph analytics libraries?">
            These libraries offer a wide range of algorithms to make it simple
            for Data Scientists to tackle problems such as viral spreading or
            identifying influential customers. Motif complements such libraries
            and cuts down the time needed to convert these insights into graph
            visualisations and allows data science teams to share graph their
            findings across different business teams.
          </Panel>
          <Panel title="What's your motivation in developing Motif?">
            We hope to lower the barriers to entry for anyone working on
            graph-related problems. One of the main pain points we faced is that
            it takes too much effort to integrate graph data into business
            decision making in areas like finance and fraud detection. Most
            solutions are often custom-built, proprietary, time-consuming and
            expensive. With Motif we are changing this by making 80% of the most
            common network visualisation use cases as easy as possible, and add
            on collaborative features to make graph data into business
            intelligence.
          </Panel>
          <Panel title="Can you support Jupyter Notebook Integration / Neo4j / AWS S3 etc.">
            Sure, we plan to support integration with notebooks, neo4j and your
            favourite tools. This will be rolled out through the months. Let us
            know what you are looking to integrate with through our contact form
            to help us prioritise development. Thanks!
          </Panel>
          <Panel title="Do you have a demo?">
            Most certainly,{" "}
            <a href="http://www.motif.gl/register" target="_blank">
              click here to try it out. 
            </a>
            .
          </Panel>
          <Panel title="What's in the enterprise version?">
            A backend service including graph storage, team access and granular
            file sharing. Comments and annotations can also be added on the
            graph. Custom data integrations or widgets can be included to better
            suit your business use case.
          </Panel>
          <Panel title="I am interested! When is the enterprise version expected to be launch?">
            We are currently piloting Motif at a few financial institutions and
            are looking to release it as a open source project with an
            enterprise offering. If you are interested to pilot the enterprise
            solution with us, kindly drop us a message at our
            <Link to="/contact-us">contact us form</Link>.
          </Panel>
          <Panel title="What data is being collected and where is it being stored?">
            For the demo application, only user session data is being collected
            by HelpHero and is used to power the product tours. Motif is a
            stand-alone front-end application and no additional data is
            collected or stored anyway beyond your computer. For the enterprise
            version, we provide a storage and collaboration server. This can be
            deployed to a cloud platform of your choice or on-premise.
          </Panel>
        </Accordion>
      </Container>
    </Block>
  )
}

export default MotifFAQs
